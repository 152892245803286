<template>
  <div class="page">
    <el-row :gutter="24">
      <el-col :span="10">
    <h2>售后单   <span style="color: #F56C6C">{{supportDetail.state_label}}</span></h2>
    <div class="afterSaleDetail">
      <span>售后编码：{{supportDetail.support_sn}}</span>
      <span>售后类型：{{supportDetail.kind_label}}</span>
      <span>退款方式：{{supportDetail.refund_type == 0 ?'原路返回':supportDetail.refund_type == 1 ?'账户余额':''}}</span>
      <span>退款金额：{{supportDetail.refund_money}}</span>
      <span v-if="supportDetail.return_addr">买家地址：{{supportDetail.return_addr.country}}{{supportDetail.return_addr.province}}{{supportDetail.return_addr.city}}{{supportDetail.return_addr.area}}{{supportDetail.return_addr.street}}</span>
      <span>用户备注：{{supportDetail.descr}}</span>
      <span>后台备注：{{supportDetail.remark}}</span>
      <span v-if="supportDetail.imgs">售后图片：</span>
      <el-image v-if="supportDetail.imgs" style="width: 100px;height: 100px" :src="supportDetail.imgs" :preview-src-list="[supportDetail.imgs]"></el-image>
    </div>
    <h2>买家快递信息</h2>
    <div class="customerInfo">
      <span>快递公司：</span>
      <span>快递单号：</span>
      <div>
        <span>最后快递信息：</span>
        <span>(<el-button type="text">查看明细</el-button>/<el-button type="text">刷新</el-button>)</span>
      </div>
    </div>
    <h2>卖家换货快递信息</h2>
    <div class="sellerInfo">
      <span>快递公司：</span>
      <span>快递单号：</span>
      <div>
        <span>最后快递信息：</span>
        <span>(<el-button type="text">查看明细</el-button>/<el-button type="text">刷新</el-button>)</span>
      </div>
    </div>
      </el-col>
      <el-col :span="10" :offset="2">
        <el-card class="box-card">
          <el-button type="primary" @click="toOrderDetail">查看订单</el-button>
          <el-button type="primary" @click="confirmAfterSale">同意售后</el-button>
          <el-button type="primary" @click="rejectAfterSale">拒绝售后</el-button>
          <el-button type="primary" @click="sellerGet">商家收货</el-button>
          <el-button type="primary" @click="sellerWaitGet">商家待收货</el-button>
          <el-button type="primary" @click="scloseAfterSale">关闭售后</el-button>
          <el-button type="primary" @click="showSetCustomersLogistics = true">录入买家物流</el-button>
          <setCustomersLogistics :visible.sync="showSetCustomersLogistics" :id="orderId"/>
          <el-button type="primary" @click="showSetChangeLogistics = true">录入换货物流</el-button>
          <setChangeLogistics :visible.sync="showSetChangeLogistics" :id="orderId"/>
          <el-button type="primary" @click="setMemo">添加备忘</el-button>
<!--          <el-button type="primary" @click="showAfterSaleIndemnity = true">补偿买家</el-button>-->
          <el-button type="primary" @click="showRefunds = true">退款</el-button>
          <el-badge is-dot :hidden="supportDetail.chat.lengt=0" style="margin: 5px">
            <el-button type="primary" @click="drawer = true" style="margin: 0">客服</el-button>
          </el-badge>
<!--          <afterSaleIndemnity :visible.sync="showAfterSaleIndemnity" title="补偿买家" :id="orderId"/>-->
        </el-card>
      </el-col>
    </el-row>
    <h2>商品列表和费用信息</h2>
    <el-table border :data="supportDetail.order_goods">
      <el-table-column label="商品">
        <template slot-scope="scope">
          <div class="goodsInfo">
            <el-image class="goodsImg" :src="scope.row.pic" fit="contain"/>
            <div class="infoView">
              <span>{{scope.row.goods_name}}</span>
              <span>{{scope.row.sku}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="单价" prop="unit_price"/>
      <el-table-column label="数量" prop="nums"/>
      <el-table-column label="小记" prop="subtotal"/>
    </el-table>
    <span style="color: #F56C6C">订单共{{ supportDetail.order_goods.length }}件商品，总计：{{ supportDetail.order_obj.order_amount_total }}</span>
    <template v-if="supportDetail.refund_history.length > 0">
      <h2>退款记录</h2>
      <el-table :data="supportDetail.refund_history">
        <el-table-column label="时间">
          <template slot-scope="scope">
            <span>{{scope.row.create_at | formatDate}}</span>
          </template>
        </el-table-column>
        <el-table-column label="金额">
          <template slot-scope="scope">
            <span>{{scope.row.amount}} {{scope.row.currency}}</span>
          </template>
        </el-table-column>
        <el-table-column label="退款渠道" prop="pay_code"></el-table-column>
        <el-table-column label="退款信息" prop="pay_note"></el-table-column>
        <el-table-column label="审核信息" prop="audit_note"></el-table-column>
      </el-table>
    </template>
    <h2>操作日志</h2>
    <el-table border :data="supportDetail.history">
      <el-table-column label="操作" prop="descr">

      </el-table-column>
      <el-table-column label="操作人" prop="op">

      </el-table-column>
      <el-table-column label="订单状态" prop="state">

      </el-table-column>
      <el-table-column label="操作端口" prop="channel">

      </el-table-column>
      <el-table-column label="操作时间">
        <template slot-scope="scope">
          {{scope.row.create_at | formatDate}}
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="退款"
        :visible.sync="showRefunds"
        width="35%">
      <el-form label-position="left" label-width="80px" v-if="supportDetail.refund_op.paypal.length == 0">
        <el-form-item label="实付金额">
          <span>{{ supportDetail.refund_op.order.amount }} {{supportDetail.refund_op.order.currency}}</span>
        </el-form-item>
        <el-form-item label="退款">
          <el-input v-model="refundAmount"></el-input>
        </el-form-item>
      </el-form>
      <el-table v-if="supportDetail.refund_op.paypal.length > 0" :data="supportDetail.refund_op.paypal">
        <el-table-column label="编号" prop="order_pay_id" width="80"></el-table-column>
        <el-table-column label="金额" prop="account" width="120"></el-table-column>
        <el-table-column label="币种" prop="currency" width="100"></el-table-column>
        <el-table-column label="是否退款" width="80">
          <template slot-scope="scope">
            <span>{{scope.row.is_refunded?'已退款':'未退款'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="退款金额">
          <template slot-scope="scope">
            <span v-if="scope.row.is_refunded">该支付已退款，退款金额{{scope.row.refunded_money}}</span>
            <div v-else style="display: flex;align-items: center">
              <el-input v-model="scope.row.amount"></el-input>
              <el-button type="text" style="margin-left: 5px" @click="refundPaypal(scope.row.order_pay_id,scope.row.amount)">退款</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer" v-if="supportDetail.refund_op.paypal.length == 0">
        <el-button @click="showRefunds = false">取 消</el-button>
        <el-button type="primary" @click="refund">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer
        title="客服"
        :visible.sync="drawer"
        size="60%"
        direction="rtl">
      <div style="padding: 0 20px;width: 100%;box-sizing: border-box">
        <tinymce v-model="releaseContent"></tinymce>
        <el-button type="primary" @click="reply">回复</el-button>
        <el-divider></el-divider>
        <div class="chatList">
          <div class="chatItem" v-for="item in supportDetail.chat" style="border-bottom: 1px solid #DCDFE6">
            <div class="userInfo">
              <el-tag v-if="item.is_user">客户</el-tag>
              <span v-if="item.is_user" style="margin-left: 10px">ip {{item.extra_info.ip}}</span>
              <el-tag v-if="!item.is_user" type="success">客服</el-tag>
            </div>
            <div class="chatContent" v-html="item.content" style="margin-top: 5px" v-if="!item.is_user"></div>
            <div class="chatContent" style="margin-top: 5px" v-if="item.is_user">
              <p v-html="item.content"></p>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import confirmAfterSale from "@/views/afterSale/component/confirmAfterSale";
import rejectAfterSale from "@/views/afterSale/component/rejectAfterSale";
import sellerGet from "@/views/afterSale/component/sellerGet";
import sellerWaitGet from "@/views/afterSale/component/sellerWaitGet";
import closeAfterSale from "@/views/afterSale/component/closeAfterSale";
import setCustomersLogistics from "@/views/afterSale/component/setCustomersLogistics";
import setChangeLogistics from "@/views/afterSale/component/setChangeLogistics";
import setMemo from "@/views/afterSale/component/setMemo";
import afterSaleIndemnity from "@/views/afterSale/component/afterSaleIndemnity"
import {
  apiAcceptSupport, apiAddSellerRemark, apiCloseSupport,
  apiGetSupportDetail,
  apiRejectSupport,
  apiSellerNotReceived,
  apiSellerReceived,
  apiRefundPaypal,
  apiRefund,
  apiAfterSaleReply
} from "@/request/api";
export default {
  name: "afterSaleDetail",
  components:{
    confirmAfterSale,
    rejectAfterSale,
    sellerGet,
    sellerWaitGet,
    closeAfterSale,
    setCustomersLogistics,
    setChangeLogistics,
    setMemo,
    afterSaleIndemnity
  },
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
  },
  data(){
    return{
      showConfirmAfterSale:false,
      showRejectAfterSale:false,
      showSellerGet:false,
      showSellerWaitGet:false,
      showCloseAfterSale:false,
      showSetCustomersLogistics:false,
      showSetChangeLogistics:false,
      showSetMemo:false,
      showAfterSaleIndemnity:false,
      showRefunds:false,
      orderId:this.$route.query.id,
      supportDetail:{},
      refundAmount:'',
      drawer:false,
      releaseContent:''
    }
  },
  created() {
    this.getData()
  },
  methods:{
    getData(){
      apiGetSupportDetail({id:this.orderId}).then(res=>{
        if(res.code == 200){
          this.supportDetail = res.data;
        }
      })
    },
    editAfterSale(){
      this.$router.push({
        name: 'afterSale',
        query: {
          orderId: this.data.orderId,
          kind:'edit'
        }
      });
    },
    confirmAfterSale(){
      var str = `<p>您确认审核通过吗？</p><p>如您确认审核通过，系统将针对三种类型的售后状态进行变更：</p> <p >1. 仅退款 将变更成 待转账</p><p >2. 退货退款，将变更成 待客户退货</p><p >3. 换货，将变更成 待客户退货</p><p >tips：审核通过后一样可以再次拒绝 </p><p>退款需手动创建</p>`
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        apiAcceptSupport({id:this.orderId}).then(res=>{
          if(res.code == 200){
            this.$message({
              type: 'success',
              message: res.message
            });
            this.getData()
          }else{
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })
      }).catch(() => {
      });
    },
    rejectAfterSale(){
      var str = `<p>您确认拒绝吗？</p><p>如您确认，本次售后单将结束！客户也无法再次发起售后</p>`
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        apiRejectSupport({id:this.orderId}).then(res=>{
          if(res.code == 200){
            this.$message({
              type: 'success',
              message: res.message
            });
            this.getData()
          }else{
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })
      }).catch(() => {
      });
    },
    sellerGet(){
      var str = `<p>您确认已经收到用户发送的商品吗？</p><p>tips：确认后也可以再次设置“待收货“</p>`
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        apiSellerReceived({id:this.orderId}).then(res=>{
          if(res.code == 200){
            this.$message({
              type: 'success',
              message: res.message
            });
            this.getData()
          }else{
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })
      }).catch(() => {
      });
    },
    sellerWaitGet(){
      var str = `<p>您确认尚未收到买家快递回来的商品吗？</p><p>tips：确认后也可以再次设置“卖家已收货“</p>`
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        apiSellerNotReceived({id:this.orderId}).then(res=>{
          if(res.code == 200){
            this.$message({
              type: 'success',
              message: res.message
            });
            this.getData()
          }else{
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })
      }).catch(() => {
      });
    },
    scloseAfterSale(){
      var str = `<p>您确认关闭售后单吗？</p><p>关闭订单意味着该笔订单已完成售后。</p>`
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        apiCloseSupport({id:this.orderId}).then(res=>{
          if(res.code == 200){
            this.$message({
              type: 'success',
              message: res.message
            });
            this.getData()
          }else{
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })
      }).catch(() => {
      });
    },
    setMemo(){
      this.$prompt('请输入备忘内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[^\s]*$/,
        inputErrorMessage: '备忘内容不能为空',
        inputType: 'textarea',
      }).then(({ value }) => {
        apiAddSellerRemark({notes:value,id:this.orderId}).then(res => {
          if(res.code == 200){
            this.$message({
              type: 'success',
              message: res.message
            });
            this.getData()
          }else{
            this.$message({
              type: 'error',
              message: res.message
            });
          }
        })
      }).catch(() => {
      });
    },
    refundPaypal(id,amount){
      apiRefundPaypal({id,amount,support_id:this.orderId}).then(res => {
        if(res.code == 200){
          this.scloseAfterSale()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    refund(){
      apiRefund({order_id:this.supportDetail.refund_op.order.order_id,support_id:this.orderId,amount:this.refundAmount,currency:this.supportDetail.refund_op.order.currency}).then(res => {
        if(res.code == 200){
          this.$message.success('退款成功')
          this.scloseAfterSale()
        }else{
          this.$message.error(res.message)
        }
      })
    },
    reply(){
      apiAfterSaleReply({id:this.orderId,content:this.releaseContent}).then(res => {
        if(res.code == 200) {
          this.releaseContent = ''
          this.getData()
        }else {
          this.$message.error(res.message)
        }
      })
    },
    toOrderDetail(){
      this.$router.push({path:'/order/orderDetail',query:{id:this.supportDetail.order_id}})
    }
  },

}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
span{
  font-size: 14px;
  color: #606266;
  margin-top: 10px;
}
.afterSaleDetail,.customerInfo,.sellerInfo{
  display:flex;
  flex-direction: column;
}
.el-button {
  margin: 5px;
}
.goodsInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.goodsInfo .goodsImg {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}
.goodsInfo .infoView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 18px;
  font-size: 14px;
  color: #909399;
  font-weight: bold;
}
.goodsInfo .infoView span {
  margin:0;
}
</style>
